import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout3 from "../layouts/MainLayout3"
import Banner from "../components/quality-assurance/Banner"
import Partner from "../components/quality-assurance/Partner"
import Opportunity from "../components/web-application/Opportunity"
import Guarantee from "../components/quality-assurance/Guarantee"
import Engineering from "../components/quality-assurance/Engineering"
import WhyChoose from "../components/quality-assurance/WhyChoose"
import Testing from "../components/quality-assurance/Testing"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import CmsProcess from "../components/cms/CmsProcess"
import PowerPacked from "../components/quality-assurance/PowerPacked"
import PowerPackedMobile from "../components/quality-assurance/PowerPackedMobile"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Testimonials from "../components/hire-typescript/Testimonials"
import Testimonials2 from "../components/hire-typescript/Testimonials2"
import Kill from "../components/quality-assurance/Kill"
const Qualityassurance = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const partner = data?.strapiPage?.sections[1]
  const bugFree = data?.strapiPage?.sections[2]
  const guarantee = data?.strapiPage?.sections[3]
  const engineering = data?.strapiPage?.sections[4]
  const whyChooseSqa = data?.strapiPage?.sections[5]
  const testing = data?.strapiPage?.sections[6]
  const engament = data?.strapiPage?.sections[7]
  const processSqa = data?.strapiPage?.sections[8]
  const powerpacked = data?.strapiPage?.sections[9]
  const kill = data?.strapiPage?.sections[10]
  const faqs = data?.strapiPage?.sections[13]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout3 schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Partner strapiData={partner} />
      <Opportunity strapiData={bugFree} />
      <Guarantee strapiData={guarantee} />
      <Engineering strapiData={engineering} />
      <WhyChoose strapiData={whyChooseSqa} />
      <Testing strapiData={testing} />
      <EngagementModels strapiData={engament} sqa={true} />
      <CmsProcess strapiData={processSqa} webProcess={true} />
      {!isMobile && !isTablet ? (
        <PowerPacked strapiData={powerpacked} />
      ) : (
        <PowerPackedMobile strapiData={powerpacked} />
      )}
      <Kill strapiData={kill} />
      <WorkPortfolio
        portfolioHead={"Our Exemplary Portfolio"}
        portfolioDesc={
          "Our amazing work provides our partners with a seamless solution."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"We Are Your True Partner"}
          testiSubtitle={
            "For a decade we have been empowering businesses with flawless technology solutions and our success lies in the success of our partners."
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"We Are Your True Partner"}
          testiSubtitle={
            "For a decade we have been empowering businesses with flawless technology solutions and our success lies in the success of our partners."
          }
        />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout3>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "quality-assurance-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Qualityassurance

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
